module.exports = [{
      plugin: require('../plugins/gatsby-plugin-redux/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W53ZQMZ","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://cashhappens.com"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CashHappens.com","short_name":"Cash Happens","lang":"en-US","dir":"ltr","gcm_sender_id":"733213273952","background_color":"#ffffff","theme_color":"#00998A","display":"standalone","orientation":"portrait-primary","description":"Looking for quick cash?","scope":"/","start_url":"/","icon":"static/assets/images/logos/favicon.png","crossOrigin":"anonymous","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b45a5f3311ec481493d4a410b081c368"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
