import { PegasusPageView, PegasusDataLayer } from '@brandedholdings/react-components';
import siteMeta from './src/data/site_meta.yml';

const dataLayer = new PegasusDataLayer(siteMeta.siteMeta);
const pageView = new PegasusPageView(siteMeta.siteMeta);

dataLayer.subscribe();

export const onRouteUpdate = () => {
    pageView.postPageView();
};

export const disableCorePrefetching = () => {
    // disable prefetching
};
