import React from 'react';
import { Provider } from 'react-redux';
import { rootStore } from '@brandedholdings/react-components';

export const wrapRootElement = ({ element }) => {
    const ConnectedRootElement = (
        <Provider store={rootStore}>
            { element }
        </Provider>
    );

    return ConnectedRootElement;
};
